import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../images/eric";
import SEO from "../components/seo";
import styles from "./index.module.scss";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi, I'm Eric!</h1>
    <p>
      I'm a technologist based in San Francisco, California. Currently, I work
      as CTO and co-founder at <a href="https://www.kapwing.com">Kapwing</a>.
      Connect with me on Twitter:{" "}
      <a href="https://www.twitter.com/realericlu">@realericlu</a>.
    </p>
    <div className={styles.image}>
      <Image />
    </div>
    <p>
      This page exists as a personal space for my{" "}
      <Link to="/projects">projects</Link>, writing, ideas, and more. Hope that
      you have a great day!
    </p>
  </Layout>
);

export default IndexPage;
